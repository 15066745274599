<template>
  <div class="h-100">
    <div class="d-none d-lg-block">
      <AppTable
        class="h-100"
        :headers="header"
        :items="items.data"
        :currentPage="items.current_page"
        :total="items.total"
        @pageChange="(page) => $emit('pageChange', page)"
        @sort="sortBy"
      >
        <template v-slot:item="{ shownItem }">
          <!-- RFQ Detail -->
          <td>
            {{ shownItem.id | rfqFormat }}
          </td>
          <!-- PO Number -->
          <td>
            {{ shownItem.po_number || 'N/A' }}
          </td>
          <!-- Buyer Detail -->
          <td class="d-flex align-items-center">
            <img
              src="@/assets/pocketyard_logo_black.svg"
              class="rounded-circle mr-3"
              width="30"
              height="30"
            />
            <div style="width: 150px">
              <p class="m-0">
                {{
                  shownItem.order
                    ? shownItem.order.user.information
                    : shownItem.user.information | userFullName
                }}
              </p>
              <p class="m-0 text-gray text-truncate">
                {{
                  shownItem.order
                    ? shownItem.order.user.information
                    : shownItem.user.information | userCompany
                }}
              </p>
            </div>
          </td>
          <!-- Order Date -->
          <td>
            <span>{{ shownItem.created_at | readableDate(false) }}</span>
            <span class="ml-1 text-gray">
              {{ shownItem.created_at | readableTime }}
            </span>
          </td>
          <!-- Bidding End Date -->
          <td>
            <span class="d-inline-block">{{
              shownItem.bidding_end_date
                | readableDate(false, 'yyyy-MM-dd HH:mm:ss')
            }}</span>
            <span class="ml-1 d-inline-block text-gray">
              {{
                shownItem.bidding_end_date | readableTime('yyyy-MM-dd HH:mm:ss')
              }}
            </span>
          </td>
          <!-- Delivery Date -->
          <td>
            <span class="d-inline-block">{{
              shownItem.delivery_date ||
              shownItem.order.delivery_date
                | readableDate(false, 'yyyy-MM-dd HH:mm:ss')
            }}</span>
          </td>
          <td>Materials and Supplies</td>
          <td class="text-info">
            <div class="d-flex align-items-center justify-center">
              <template v-if="isOrder">
                <router-link
                  v-if="[STATUS.QUOTE_APPROVED.value].includes(status) && hasMyQuote(shownItem)"
                  :to="{
                    name: 'QuoteView',
                    params: { id: myQuote(shownItem) },
                  }"
                  class="
                    order-table-action
                    text-decoration-none
                    d-flex
                    align-items-center
                    mr-2
                  "
                >
                  <AppIcon name="document"></AppIcon>
                  <p :class="[{'hovered': isHovered}, 'm-0', 'ml-1']" @mouseover="isHovered = true" @mouseleave="isHovered = false">View Quotations</p>
                </router-link>
                <router-link
                  v-else
                  class="
                    order-table-action
                    text-decoration-none
                    d-flex
                    align-items-center
                  "
                  :to="{ name: 'OrderView', params: { id: shownItem.id } }"
                >
                  <AppIcon name="document"></AppIcon>
                  <p :class="[{'hovered': isHovered}, 'm-0', 'ml-1']" @mouseover="isHovered = true" @mouseleave="isHovered = false">
                    View {{ shouldViewRFQ ? 'RFQ' : 'Order' }}
                  </p>
                </router-link>
              </template>
              <template v-else>
                <router-link
                  :to="{
                    name: 'QuoteView',
                    params: { id: shownItem.id },
                  }"
                  class="
                    order-table-action
                    text-decoration-none
                    d-flex
                    align-items-center
                    mr-2
                  "
                >
                  <AppIcon name="document"></AppIcon>
                  <p :class="[{'hovered': isHovered}, 'm-0', 'ml-1']" @mouseover="isHovered = true" @mouseleave="isHovered = false">View Quotation</p>
                </router-link>
              </template>
            </div>
          </td>
        </template>
      </AppTable>
    </div>
    <!-- Mobile View -->
    <div class="d-md-none" v-if="items.data">
      <template v-if="items.data.length > 0">
        <AppCard
            v-for="(shownItem, index) in items.data"
            :key="`order-item-${index}`"
            class="p-3 mb-3"
            @click="redirectToDetails(shownItem)"
        >
          <div class="mb-4 d-flex align-items-center justify-content-between">
            <!-- Project Name -->
            <span
                v-if="
              status === STATUS.REQUEST_APPROVED.value ||
              status === STATUS.QUOTE_SENT.value
            "
                class="font-weight-6 w-100 text-truncate"
            >
            {{ shownItem.project_name }}
          </span>
            <!-- <span v-else class="font-weight-6 w-100 text-truncate">
              {{ shownItem.order.project_name }}
            </span> -->
            <!-- Status -->
            <div class="flex-shrink-0">
            <span
                class="p-1 d-inline-block text-center text-caption-2"
                :class="[
                statusColor,
                { 'text-success-dark-1': status === 'complete' },
              ]"
            >
              <AppIcon
                  v-if="
                  status === STATUS.QUOTE_APPROVED.value ||
                  status === STATUS.COMPLETED.value
                "
                  size="12"
                  name="checkmark"
                  class="d-inline"
              ></AppIcon>
              <AppIcon
                  v-if="status === STATUS.COMPLETED.value"
                  name="checkmark"
                  size="12"
                  class="ml-n3 pl-2 d-inline"
              ></AppIcon>
              {{ statusName }}
            </span>
            </div>
          </div>
          <!-- User -->
          <div class="d-flex align-items-center">
            <div>
              <AppMediaViewer
                  width="45px"
                  aspectRatio="1/1"
                  class="rounded-circle"
                  :src="
                shownItem.user.information
                  ? shownItem.user.information.profile_img
                  : ''
              "
              ></AppMediaViewer>
            </div>
            <div class="ml-2 d-flex flex-column">
              <!-- USer Name -->
              <span class="">
              {{
                  shownItem.order
                      ? shownItem.order.user.information
                      : shownItem.user.information | userFullName
                }}
            </span>
              <!-- User Company -->
              <span class="text-body-2">
              {{
                  shownItem.order
                      ? shownItem.order.user.information
                      : shownItem.user.information | userCompany
                }}
            </span>
            </div>
          </div>
          <!-- RFQ -->
          <div class="row mt-3">
            <div class="col-6 font-weight-3">RFQ</div>
            <div class="col-6">{{ shownItem.id | rfqFormat }}</div>
          </div>
          <!-- PO NUMBER -->
          <div class="row mt-3">
            <div class="col-6 font-weight-3">PO Number</div>
            <div class="col-6">{{ shownItem.po_number || 'N/A' }}</div>
          </div>
          <!-- End Bidding Date -->
          <div
              v-if="
            status === STATUS.REQUEST_APPROVED.value ||
            status === STATUS.QUOTE_SENT.value
          "
              class="row mt-3"
          >
            <div class="col-6 font-weight-3">Bidding Date End</div>
            <div class="col-6">
            <span>
              {{ shownItem.bidding_end_date | readableDate(false, true) }}
            </span>
              <span class="d-inline-block text-gray">
              {{ shownItem.bidding_end_date | readableTime(true) }}
            </span>
            </div>
          </div>
          <!-- Delivery Date Date -->
          <div v-else class="row mt-3">
            <div class="col-6 font-weight-3">Delivery Date</div>
            <div class="col-6">
              {{ shownItem.delivery_date | readableDate(false, true) }}
            </div>
          </div>
          <AppIcon
              @click="confirmDeleteOrder(shownItem.id)"
              name="trash_can"
          ></AppIcon>
        </AppCard>
        <AppPagination
          v-if="items.data"
          hideDetails
          centerPagination
          itemsPerPage="10"
          :currentPage="items.current_page"
          :total="items.total"
          @pageChange="(page) => $emit('pageChange', page)"
        ></AppPagination>
      </template>
      <template v-else>
        <h4 class="text-center font-weight-1">No data available</h4>
      </template>
    </div>
    <ActionModal
      v-model="deleteOrderModal"
      @confirm="deleteOrder"
      @cancel="deleteOrderId = null"
    >
      <template v-slot:title
        >Are you sure you want to delete this request?</template
      >
      <template v-slot:description> Click confirm to proceed </template>
    </ActionModal>
    <ActionModal
      v-model="deleteOrderSuccessModal"
      state="success"
      description=""
      @cancel="emitDeleted"
    >
      <template v-slot:title>Quotation successfully deleted</template>
    </ActionModal>

    <SuccessModal
      v-model="deleteSuccessModal"
      @confirm="deleteSuccessModal = false"
    >
      <template v-slot:description> Request deleted successfully </template>
    </SuccessModal>
  </div>
</template>

<script>
import placeholder from '@/assets/pocketyard_logo_black.svg';
import AppTable from '@/shared/elements/AppTable.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import _readableDateTime from '@/shared/mixins/_readableDateTime';
import _rfqFormat from '@/shared/mixins/_rfqFormat';
import { STATUS } from '@/shared/constants/OrderConstants';
import AppCard from '../../../../shared/elements/AppCard.vue';
import AppMediaViewer from '../../../../shared/elements/AppMediaViewer.vue';
import AppPagination from '../../../../shared/elements/AppPagination.vue';
import ActionModal from '@/components/Partial/Modals/ActionModal.vue';
import { deleteQuote } from '@/shared/api/Orders.js';
import SuccessModal from '@/components/Partial/Modals/SuccessModal.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'VendorOrderRequest',

  components: {
    AppTable,
    AppIcon,
    AppCard,
    AppMediaViewer,
    AppPagination,
    ActionModal,
    SuccessModal,
  },

  mixins: [_readableDateTime, _rfqFormat],

  props: {
    items: { type: Object, default: () => {} },
    status: { type: String, default: 'pending' },
    sort: { type: Array, default: () => [] },
    isOrder: { type: Boolean, default: true },
  },

  data() {
    return {
      isHovered: false,
      STATUS,
      placeholder,
      loading: false,
      currentPage: 1,
      sorts: this.sort,
      deleteOrderModal: false,
      deleteOrderSuccessModal: false,
      deleteSuccessModal: false,
      deleteOrderId: null,
      header: [
        {
          text: 'RFQ ID',
          value: 'rqf_num',
          sortable: false,
        },
        {
          text: 'PO',
          value: 'po_number',
          sortable: false,
        },
        {
          text: 'Customer',
          value: 'buyer',
          sortable: false,
        },
        {
          text: 'RFQ Date',
          value:
            this.status === STATUS.QUOTE_APPROVED.value ||
            status === STATUS.COMPLETED.value
              ? 'order_order_created_at'
              : 'order_created_at',
          desc: this.isAsc(
            this.status === STATUS.QUOTE_APPROVED.value ||
              status === STATUS.COMPLETED.value
              ? 'order_order_created_at'
              : 'order_created_at'
          ),
        },
        {
          text: 'Bidding Date End',
          value: 'order_bidding_end_date',
          desc: this.isAsc('order_bidding_end_date'),
        },
        {
          text: 'Delivery Date',
          value: 'order_delivery_date',
          desc: this.isAsc('order_delivery_date'),
        },
        {
          text: 'Order Type',
          value: 'order_type',
        },
        {
          text: '',
          sortable: false,
        },
      ],
    };
  },

  watch: {
    'isHovered': {
      handler() {

      }
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),

    statusName() {
      switch (this.status) {
        case STATUS.COMPLETED.value:
          return 'Completed';
        case STATUS.QUOTE_SENT.value:
          return 'Quotation Sent';
        case STATUS.QUOTE_APPROVED.value:
          return 'Quotation Approved';
        case STATUS.REQUEST_APPROVED.value:
        default:
          return 'Quotation Request';
      }
    },
    statusColor() {
      switch (this.status) {
        case STATUS.COMPLETED.value:
          return 'bg-success-light-9';
        case STATUS.QUOTE_APPROVED.value:
          return 'bg-success-dark-2 text-white';
        case STATUS.QUOTE_SENT.value:
          return 'bg-special-light-3';
        case STATUS.REQUEST_APPROVED.value:
        default:
          return 'bg-special-light-3';
      }
    },

    shouldViewRFQ() {
      return [STATUS.REQUEST_APPROVED.value, STATUS.QUOTE_SENT.value].includes(
        this.status
      );
    },
  },

  filters: {
    userFullName(value) {
      if (value && (value.first_name || value.last_name)) {
        return `${value.first_name} ${value.last_name} `;
      } else {
        return 'N/A';
      }
    },
    userCompany(value) {
      if (value && value.company_name) {
        return value.company_name;
      } else {
        return 'N/A';
      }
    },
  },

  methods: {
    sortBy({ item, desc }) {
      const existingIndex = this.sorts.findIndex(
        (value) => value.item === item
      );
      if (existingIndex < 0) {
        this.sorts.push({ item, orderBy: desc ? 'DESC' : 'ASC' });
      } else {
        this.sorts[existingIndex].orderBy = desc ? 'DESC' : 'ASC';
      }
      this.$emit('sort', this.sorts);
    },
    isAsc(value) {
      return this.sort.find((val) => val.item === value)?.orderBy === 'DESC';
    },
    redirectToDetails(id) {

      if (this.status === 'quote_approved') {
        id = this.myQuote(id)
      } else {
        id = id.id
      }

      let routeObject = null;

      switch (this.status) {
        case STATUS.QUOTE_APPROVED.value:
          routeObject = { name: 'QuoteView', params: { id: id } };
          break;
        case STATUS.COMPLETED.value:
          routeObject = { name: 'OrderView', params: { id: id } };
          break;
        case STATUS.REQUEST_APPROVED.value:
          routeObject = { name: 'OrderView', params: { id: id } };
          break;
        case STATUS.QUOTE_SENT.value:
        default:
          routeObject = { name: 'OrderView', params: { id: id } };
          break;
      }

      console.log(this.status)

      this.$router.push(routeObject);
    },
    confirmDeleteOrder(id) {
      this.deleteOrderId = id;
      this.deleteOrderModal = true;
    },
    emitDeleted() {
      this.$emit('deleteOrder');
      this.$emit('delete-order');
    },
    deleteOrder() {
      if (!this.deleteOrderId) {
        return;
      }

      const formData = {
        status: 'rejected',
      };

      deleteQuote(this.deleteOrderId, formData).then(() => {
        this.deleteOrderSuccessModal = true;
        this.$emit('delete-order');
        this.deleteSuccessModal = true;
      });
    },
    myQuote(item) {
      let quotes = item.quotations;

      if (!quotes || quotes.length <= 0) {
        return -1;
      }

      quotes = quotes.filter((q) => q.user_id === this.user.id);

      if (quotes.length <= 0) {
        return -1;
      }

      return quotes[0].id;
    },
    hasMyQuote(item) {
      return this.myQuote(item) !== -1;
    }
  },
};
</script>

<style lang="scss" scoped>
.order-table {
  &-action {
    p {
      // display: none;
      max-width: 0;
      overflow: hidden;
      transition: max-width 0.5s ease-in-out;
      white-space: nowrap;
    }
    &:hover {
      p {
        max-width: 200px;
      }
    }
  }
}

.hovered {
  max-width: 200px !important;
}
</style>
