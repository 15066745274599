<template>
  <LayoutVendor
    :sidebarActiveItem="{
      value: 'order',
      children: ['accepted'],
    }"
  >
    <template v-slot:title>
      <span>Accepted Orders</span>
    </template>
    <div class="order-request p-2 p-md-5 h-100 bg-gray-light-4">
      <GoBackBtn class="mb-5">Back</GoBackBtn>
      <AppLoading fillSpace v-if="isLoading"></AppLoading>
      <VendorOrderTable
        v-else
        :isOrder="false"
        :status="STATUS.QUOTE_APPROVED.value"
        :items="orders"
        :sort="sorts"
        @sort="sortBy"
        @pageChange="fetchOrders"
        @deleteOrder="fetchOrders"
      ></VendorOrderTable>
    </div>
  </LayoutVendor>
</template>

<script>
import Orders from '@/shared/api/Orders';
import AppLoading from '@/shared/elements/AppLoading.vue';
import VendorOrderTable from '@/components/Partial/Orders/OrderTables/VendorOrderTable.vue';
import { STATUS } from '@/shared/constants/OrderConstants';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import LayoutVendor from '@/components/Partial/Layouts/LayoutVendor/LayoutVendor.vue';
import GoBackBtn from '@/components/Partial/GoBackBtn.vue';

export default {
  name: 'VendorQuoteList',

  components: { AppLoading, VendorOrderTable, LayoutVendor, GoBackBtn },

  mixins: [_appApiHelper],

  props: {
    status: { type: String, default: STATUS.QUOTE_APPROVED.value },
  },

  data() {
    return {
      STATUS,
      orders: {},
      id: this.$route.params.id,
      isLoading: false,
      sorts: [{ item: 'order_created_at', orderBy: 'DESC' }],
    };
  },

  watch: {
    async status() {
      await this.fetchOrders();
    },
  },

  methods: {
    async fetchOrders(page = 1) {
      this.isLoading = true;

      const sorts = {};
      this.sorts.forEach((value) => {
        sorts[value.item] = value.orderBy.toLowerCase();
      });

      const params = {
        page,
        order_id: this.id,
        ...sorts,
        fetch_by_company: 1,
      };

      if (this.status === STATUS.QUOTE_APPROVED.value) {
        params.approved_only = 1;
      } else if (this.status === STATUS.COMPLETED.value) {
        params.completed_only = 1;
      }

      this.orders = (await Orders.getQuotes({ params })).data;

      this.isLoading = false;
    },
    sortBy(sorts) {
      this.sorts = sorts;
      this.fetchOrders();
    },
  },

  async mounted() {
    await this.fetchOrders();
  },
};
</script>

<style lang="scss" scoped></style>
